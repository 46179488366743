import moment from 'moment';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import InputNumberDias from '~/components/Inputs/inputNumberDias';
import { InpuTypeDate } from '~/components/Pessoa/styles';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import { ProdutoContext, useProduto } from '~/pages/Produto/ProdutoContext';
import {
  FormAssociado,
  InformacaoNutricional,
  Receita,
  Select,
  Tara,
} from '~/pages/Produto/protocols';
import { tipoMultiplicacao } from '~/pages/Produto/types';
import api from '~/services/api';

import { BuscaVasilhame } from './BuscaVasilhame';
import { InterfaceContainer } from './styles';
import {
  InputText,
  InputSelect,
  InputNumber2,
  InputPercent,
} from '~/components/NovosInputs';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';
import { moedaFormatada } from '~/components/Inputs/InputPercent2/formatMask';

const tipos_composicao = [
  {
    value: 0,
    label: 'Direta',
  },
  {
    value: 1,
    label: 'Decomposição',
  },
  {
    value: 2,
    label: 'Composição',
  },
  {
    value: 3,
    label: 'Produção',
  },
];

export const AbaInterface: React.FC = () => {
  const {
    formProduto,
    changeFormProduto,
    formProdutoLoja,
    changeFormProdutoLoja,
  } = useContext(ProdutoContext);
  const [receitas, setReceitas] = useState<Select[]>([]);
  const [infosNutris, setInfosNutris] = useState<Select[]>([]);
  const [taras, setTaras] = useState<Select[]>([]);
  const [atualizaCustos, setAtualizaCustos] = useState(
    formProdutoLoja.flg_atualiza_custo_decomp.value,
  );

  const {
    setValue,
    register,
    control,
    formState: { errors },
  } = useProduto();

  useEffect(() => {
    getReceitas();
    getInfosNutris();
    getTaras();
  }, []);

  useEffect(() => {
    if (
      !formProduto.flg_explosao_compra.value ||
      formProduto.tipo_movimentacao.value.value !== 1
    ) {
      setAtualizaCustos(false);
    }
  }, [
    formProduto.flg_explosao_compra.value,
    formProduto.tipo_movimentacao.value.value,
  ]);

  useEffect(() => {
    setValue('dta_fabricacao_rast', moment().format('YYYY-MM-DD'));
    if (formProduto.flg_rastreavel.value) {
      changeFormProdutoLoja({
        ...formProdutoLoja,
        num_lote_rast: {
          value: '',
          isRequired: true,
          isInvalid: true,
        },
        qtd_lote_rast: {
          value: 1,
          isRequired: false,
          isInvalid: false,
        },
        cod_agreg_rast: {
          value: 1,
          isRequired: false,
          isInvalid: false,
        },
        dta_fabricacao_rast: {
          value: moment().format('YYYY-MM-DD'),
          isRequired: false,
          isInvalid: false,
        },
      });
    } else {
      changeFormProdutoLoja({
        ...formProdutoLoja,
        num_lote_rast: {
          value: '',
          isRequired: false,
          isInvalid: false,
        },
        qtd_lote_rast: {
          value: 1,
          isRequired: false,
          isInvalid: false,
        },
        cod_agreg_rast: {
          value: 1,
          isRequired: false,
          isInvalid: false,
        },
        dta_fabricacao_rast: {
          value: moment().format('YYYY-MM-DD'),
          isRequired: false,
          isInvalid: false,
        },
      });
    }
  }, [formProduto.flg_rastreavel.value]);

  async function getReceitas() {
    try {
      const res = await api.get('/receita');
      const { data, success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }

      const regs = data.map((reg: Receita) => {
        return {
          value: reg.cod_receita,
          label: reg.des_receita,
        };
      });
      regs.unshift({
        value: -1,
        label: 'NENHUM(A)',
      });

      setReceitas(regs);
    } catch (e: any) {
      toast.error(e.message);
    }
  }

  async function getInfosNutris() {
    try {
      const res = await api.get('/informacaoNutricional');
      const { data, success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }

      const regs = data.map((reg: InformacaoNutricional) => {
        return {
          value: reg.cod_info_nutricional,
          label: reg.des_info_nutricional,
        };
      });

      const sortedRegs = regs.sort((a: any, b: any) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
      );

      sortedRegs.unshift({
        value: -1,
        label: 'NENHUM(A)',
      });
      setInfosNutris(sortedRegs);
    } catch (e: any) {
      toast.error(e.message);
    }
  }

  async function getTaras() {
    try {
      const res = await api.get('/tara');
      const { data, success, message } = res.data;
      if (!success) {
        throw new Error(message);
      }

      const regs = data.map((reg: Tara) => {
        return {
          value: reg.cod_tara,
          label: reg.des_tara,
        };
      });
      regs.unshift({
        value: -1,
        label: 'NENHUM(A)',
      });
      setTaras(regs);
    } catch (e: any) {
      toast.error(e.message);
    }
  }

  function handleChangeFlgRastreavel() {
    setValue('num_lote_rast', '');
    setValue('qtd_lote_rast', 0);
    setValue('cod_agreg_rast', 0);
    setValue('dta_fabricacao_rast', undefined);
    changeFormProdutoLoja({
      ...formProdutoLoja,
      num_lote_rast: {
        ...formProdutoLoja.num_lote_rast,
        value: '',
        isInvalid: !formProduto.flg_rastreavel.value,
        isRequired: !formProduto.flg_rastreavel.value,
      },
      qtd_lote_rast: {
        ...formProdutoLoja.qtd_lote_rast,
        value: 0,
        isInvalid: !formProduto.flg_rastreavel.value,
        isRequired: !formProduto.flg_rastreavel.value,
      },
      cod_agreg_rast: {
        ...formProdutoLoja.cod_agreg_rast,
        value: undefined,
        isInvalid: !formProduto.flg_rastreavel.value,
        isRequired: !formProduto.flg_rastreavel.value,
      },
      dta_fabricacao_rast: {
        ...formProdutoLoja.dta_fabricacao_rast,
        value: undefined,
        isInvalid: !formProduto.flg_rastreavel.value,
        isRequired: !formProduto.flg_rastreavel.value,
      },
    });
    setValue('flg_rastreavel', !formProduto.flg_rastreavel.value);
    changeFormProduto({
      ...formProduto,
      flg_rastreavel: {
        ...formProduto.flg_rastreavel,
        value: !formProduto.flg_rastreavel.value,
      },
    });
  }

  useEffect(() => {
    setValue(
      'cod_receita',
      receitas.find((item: Select) => {
        return item.value === formProdutoLoja.cod_receita.value.value;
      }) ?? {
        value: undefined,
        label: 'Selecione a Receita',
      },
    );
  }, [formProdutoLoja.cod_receita.value.value, receitas, setValue]);

  useEffect(() => {
    setValue(
      'cod_info_nutricional',
      infosNutris.find((item: Select) => {
        return item.value === formProduto.cod_info_nutricional.value.value;
      }) ?? {
        value: undefined,
        label: 'Selecione a Inf. Nutr.',
      },
    );
  }, [formProduto.cod_info_nutricional.value.value, infosNutris, setValue]);

  useEffect(() => {
    setValue(
      'cod_tara',
      taras.find((item: Select) => {
        return item.value === formProdutoLoja.cod_tara.value.value;
      }) ?? {
        value: undefined,
        label: 'Selecione a Tara',
      },
    );
  }, [formProdutoLoja.cod_tara.value.value, setValue, taras]);

  useEffect(() => {
    setValue(
      'tipo_multiplicacao',
      tipoMultiplicacao.find((item: Select) => {
        return item.value === formProduto.tipo_multiplicacao.value.value;
      }) ?? {
        value: undefined,
        label: 'Selecione',
      },
    );
  }, [formProduto.tipo_multiplicacao.value.value, setValue]);

  useEffect(() => {
    setValue(
      'tipo_movimentacao',
      tipos_composicao.find((item: Select) => {
        return item.value === formProduto.tipo_movimentacao.value.value;
      }) ?? {
        value: 0,
        label: 'Direta',
      },
    );
  }, [formProduto.tipo_movimentacao.value.value, setValue]);

  return (
    <InterfaceContainer>
      <Row>
        <Col xs={6} sm={6} md={3} lg={2} xl={2}>
          <ToggleDefault
            labelText="Peso Variável?"
            setChecked={formProduto.flg_ipv.value}
            onSwitch={() => {
              setValue('flg_ipv', !formProduto.flg_ipv.value);
              changeFormProduto({
                ...formProduto,
                flg_ipv: {
                  ...formProduto.flg_ipv,
                  value: !formProduto.flg_ipv.value,
                },
              });
            }}
          />
        </Col>
        <Col xs={6} sm={6} md={3} lg={2} xl={2}>
          <ToggleDefault
            labelText="Enviar para Balança?"
            setChecked={formProduto.flg_envia_balanca.value}
            onSwitch={() => {
              setValue(
                'flg_envia_balanca',
                !formProduto.flg_envia_balanca.value,
              );
              changeFormProduto({
                ...formProduto,
                flg_envia_balanca: {
                  ...formProduto.flg_envia_balanca,
                  value: !formProduto.flg_envia_balanca.value,
                },
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={3} lg={2} xl={2}>
          <InputNumberDias
            label="Validade"
            min={0}
            value={formProduto.qtd_dias_validade.value}
            placeholder=""
            isRequired={formProduto.qtd_dias_validade.isRequired}
            setInvalid={formProduto.qtd_dias_validade.isInvalid}
            isDisabled={false}
            onChange={(value: number) => {
              setValue('qtd_dias_validade', Math.abs(value));
              changeFormProduto({
                ...formProduto,
                qtd_dias_validade: {
                  ...formProduto.qtd_dias_validade,
                  value: Math.abs(value),
                },
              });
            }}
          />
        </Col>
        <Col sm={12} md={3} lg={2} xl={2}>
          <InputText
            maxLength={5}
            label="Tecla Balança"
            placeholder=""
            register={register}
            isError={!!errors.tecla_balanca}
            name="tecla_balanca"
            onInput={(ev: ChangeEvent<HTMLInputElement>) => {
              const { value } = ev.target;

              setValue('tecla_balanca', value);
              changeFormProdutoLoja({
                ...formProdutoLoja,
                tecla_balanca: {
                  ...formProdutoLoja.tecla_balanca,
                  value,
                },
              });
            }}
          />
        </Col>
        <Col sm={12} md={6} lg={4} xl={3}>
          <InputSelect
            options={receitas as SelectType[]}
            label="Receita"
            name="cod_receita"
            register={register}
            isError={!!errors.cod_receita}
            control={control}
            changeSelected={(selected) => {
              setValue('cod_receita', selected);
              changeFormProdutoLoja({
                ...formProdutoLoja,
                cod_receita: {
                  ...formProdutoLoja.cod_receita,
                  value: selected,
                  isInvalid: selected.value === undefined,
                },
              });
            }}
          />
        </Col>
        <Col sm={12} md={6} lg={4} xl={3}>
          <InputSelect
            options={infosNutris as SelectType[]}
            label="Informação Nutricional"
            name="cod_info_nutricional"
            register={register}
            isError={!!errors.cod_info_nutricional}
            control={control}
            changeSelected={(selected) => {
              setValue('cod_info_nutricional', selected);

              changeFormProduto({
                ...formProduto,
                cod_info_nutricional: {
                  ...formProduto.cod_info_nutricional,
                  value: selected,
                  isInvalid: selected.value === undefined,
                },
              });
            }}
          />
        </Col>
        <Col sm={12} md={6} lg={4} xl={2}>
          <InputSelect
            options={taras as SelectType[]}
            label="Tara"
            name="cod_tara"
            register={register}
            isError={!!errors.cod_tara}
            control={control}
            changeSelected={(selected) => {
              setValue('cod_tara', selected);
              changeFormProdutoLoja({
                ...formProdutoLoja,
                cod_tara: {
                  ...formProdutoLoja.cod_tara,
                  value: selected,
                  isInvalid: selected.value === undefined,
                },
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Separator labelText="PDV" />
        <Col sm={12} md={2} lg={2} xl={2}>
          <ToggleDefault
            labelText="Envia PDV?"
            setChecked={formProduto.flg_envia_pdv.value}
            onSwitch={() => {
              setValue('flg_envia_pdv', !formProduto.flg_envia_pdv.value);
              changeFormProduto({
                ...formProduto,
                flg_envia_pdv: {
                  ...formProduto.flg_envia_pdv,
                  value: !formProduto.flg_envia_pdv.value,
                },
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4} lg={3} xl={2}>
          <InputSelect
            options={tipoMultiplicacao as SelectType[]}
            label="Permite Multiplicação"
            name="tipo_multiplicacao"
            register={register}
            isError={!!errors.tipo_multiplicacao}
            control={control}
            changeSelected={(selected) => {
              setValue('tipo_multiplicacao', selected);

              changeFormProduto({
                ...formProduto,
                tipo_multiplicacao: {
                  ...formProduto.tipo_multiplicacao,
                  value: selected,
                },
              });
            }}
          />
        </Col>
        <Col sm={12} md={7} lg={4}>
          <BuscaVasilhame
            label="Vasilhame"
            value={formProdutoLoja.cod_vasilhame.value}
            refs={formProdutoLoja.cod_vasilhame.ref}
            onChange={(value) => {
              setValue('cod_vasilhame', value.value);
              changeFormProdutoLoja({
                ...formProdutoLoja,
                cod_vasilhame: {
                  ...formProdutoLoja.cod_vasilhame,
                  value: value.value,
                },
              });
            }}
            placeholder="Código ou Descrição do Vasilhame"
          />
        </Col>
        <Col sm={12} md={12}>
          <Separator labelText="Etiqueta" />
        </Col>
        <Col sm={12} md={3} lg={2} xl={2}>
          <InputNumber2
            label="Quantidade"
            min={0}
            max={9999}
            maxLength={4}
            placeholder=""
            name="qtd_etiqueta"
            register={register}
            control={control}
            isError={!!errors.qtd_etiqueta}
            onBlur={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.value === '') setValue('qtd_etiqueta', 0);
            }}
            onChange={(ev: any) => {
              const value = parseFloat(ev.target.value.slice(0, 4));

              if ((value >= 0 && value <= 9999) || ev.target.value === '') {
                setValue('qtd_etiqueta', value);
                changeFormProdutoLoja({
                  ...formProdutoLoja,
                  qtd_etiqueta: {
                    ...formProdutoLoja.qtd_etiqueta,
                    value,
                  },
                });
              }
            }}
          />
        </Col>

        <Col sm={12} md={12}>
          <Separator labelText="Movimentação" />
        </Col>
        <Col>
          <InputSelect
            label="Tipo de Movimentação"
            options={tipos_composicao as SelectType[]}
            name="tipo_movimentacao"
            register={register}
            isError={!!errors.tipo_movimentacao}
            control={control}
            changeSelected={(selected) => {
              setValue('tipo_movimentacao', selected);
              setValue('per_perda', '0,00');
              changeFormProduto({
                ...formProduto,
                tipo_movimentacao: {
                  value: selected,
                  isInvalid: false,
                  isRequired: true,
                },
                per_perda: {
                  value: '0,00',
                  isInvalid: false,
                  isRequired: true,
                },
              });
            }}
          />
        </Col>
        <Col>
          <InputPercent
            label="Perda de Decomposição"
            min={0}
            max={100}
            maxLength={5}
            decimals={2}
            placeholder="0,00"
            isDisabled={formProduto.tipo_movimentacao.value.value !== 1}
            name="per_perda"
            register={register}
            control={control}
            isError={!!errors.per_perda}
            showIcon
            onInput={(ev: any) => {
              const serializedValue = moedaFormatada(ev.target.value);

              setValue('per_perda', serializedValue);

              changeFormProduto({
                ...formProduto,
                per_perda: {
                  value: serializedValue.includes('-')
                    ? serializedValue.replace('-', '')
                    : serializedValue,
                  isInvalid: false,
                  isRequired: true,
                },
              });
            }}
          />
        </Col>
        <Col>
          <ToggleDefault
            labelText="Explosão na Compra?"
            onSwitch={() => {
              setValue(
                'flg_explosao_compra',
                !formProduto.flg_explosao_compra.value,
              );
              changeFormProduto({
                ...formProduto,
                flg_explosao_compra: {
                  value: !formProduto.flg_explosao_compra.value,
                  isInvalid: false,
                  isRequired: true,
                },
              });
            }}
            setChecked={formProduto.flg_explosao_compra.value}
          />
        </Col>
        <Col>
          <ToggleDefault
            labelText="Atualiza Custos na Explosão?"
            disabled={
              !formProduto.flg_explosao_compra.value ||
              formProduto.tipo_movimentacao.value.value !== 1
            }
            onSwitch={() => {
              setValue('flg_atualiza_custo_decomp', !atualizaCustos);
              setAtualizaCustos(!atualizaCustos);
              changeFormProdutoLoja({
                ...formProdutoLoja,
                flg_atualiza_custo_decomp: {
                  value: !atualizaCustos,
                  isInvalid: false,
                  isRequired: false,
                },
              });
            }}
            setChecked={atualizaCustos}
          />
        </Col>

        <Col>
          <ToggleDefault
            labelText="Explosão na Venda?"
            disabled={
              formProduto.tipo_movimentacao.value.value === 1 ||
              formProdutoLoja.Associados.associados.some(
                (item: FormAssociado) => item.qtd_estoque.value > 0,
              )
            }
            onSwitch={() => {
              setValue(
                'flg_explosao_venda',
                !formProduto.flg_explosao_venda.value,
              );
              changeFormProduto({
                ...formProduto,
                flg_explosao_venda: {
                  value: !formProduto.flg_explosao_venda.value,
                  isInvalid: false,
                  isRequired: true,
                },
              });
            }}
            setChecked={formProduto.flg_explosao_venda.value}
          />
        </Col>

        <Col sm={12} md={12}>
          <Separator labelText="iPDV" />
        </Col>

        <Col xs={12} sm={6} md={3} lg={3} xl={2}>
          <ToggleDefault
            labelText="Permite Parcelamento?"
            setChecked={formProduto.flg_permite_parc.value}
            onSwitch={() => {
              setValue('flg_permite_parc', !formProduto.flg_permite_parc.value);
              changeFormProduto({
                ...formProduto,
                flg_permite_parc: {
                  ...formProduto.flg_permite_parc,
                  value: !formProduto.flg_permite_parc.value,
                },
              });
            }}
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={2} xl={2}>
          <ToggleDefault
            labelText="Emite Vale Gás?"
            setChecked={formProdutoLoja.flg_vale_gas.value}
            onSwitch={() => {
              setValue('flg_vale_gas', !formProdutoLoja.flg_vale_gas.value);
              changeFormProdutoLoja({
                ...formProdutoLoja,
                flg_vale_gas: {
                  ...formProdutoLoja.flg_vale_gas,
                  value: !formProdutoLoja.flg_vale_gas.value,
                },
              });
            }}
          />
        </Col>
        <Col xs={12} sm={6} md={3} lg={2} xl={2}>
          <ToggleDefault
            labelText="Pede Liberação Fiscal?"
            setChecked={formProdutoLoja.flg_lib_fiscal.value}
            onSwitch={() => {
              setValue('flg_lib_fiscal', !formProdutoLoja.flg_lib_fiscal.value);
              changeFormProdutoLoja({
                ...formProdutoLoja,
                flg_lib_fiscal: {
                  ...formProdutoLoja.flg_lib_fiscal,
                  value: !formProdutoLoja.flg_lib_fiscal.value,
                },
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          <Separator labelText="Rastreabilidade" />
        </Col>
        <Col xs={12} sm={6} md={3} lg={2} xl={2}>
          <ToggleDefault
            labelText="Item Rastreável?"
            setChecked={formProduto.flg_rastreavel.value}
            onSwitch={() => handleChangeFlgRastreavel()}
          />
        </Col>
      </Row>
      <Row className={!formProduto.flg_rastreavel.value ? 'd-none' : ''}>
        <Col sm={12} md={3}>
          <InputText
            label="Número de Lote"
            maxLength={25}
            placeholder="Digite aqui o Número do Lote"
            isDisabled={!formProduto.flg_rastreavel.value}
            name="num_lote_rast"
            register={register}
            isError={!!errors.num_lote_rast}
            autoComplete="off"
            onInput={(ev: ChangeEvent<HTMLInputElement>) => {
              const { value } = ev.target;
              changeFormProdutoLoja({
                ...formProdutoLoja,
                num_lote_rast: {
                  ...formProdutoLoja.num_lote_rast,
                  value: value.trim(),
                  isInvalid: value.trim() === '',
                },
              });
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputNumber2
            label="Quantidade"
            min={1}
            max={999999999}
            placeholder=""
            maxLength={9}
            disabled={!formProduto.flg_rastreavel.value}
            name="qtd_lote_rast"
            register={register}
            control={control}
            isError={!!errors.qtd_lote_rast}
            onBlur={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.value === '') setValue('qtd_lote_rast', 0);
            }}
            onInput={(ev: any) => {
              const value = parseFloat(ev.target.value.slice(0, 9));

              if (value <= 999999999) {
                setValue('qtd_lote_rast', value);

                changeFormProdutoLoja({
                  ...formProdutoLoja,
                  qtd_lote_rast: {
                    ...formProdutoLoja.qtd_lote_rast,
                    value,
                    isInvalid: formProduto.flg_rastreavel.value && value < 1,
                  },
                });
              }
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputNumber2
            label="Código Agregação"
            min={0}
            max={999999999}
            placeholder=""
            maxLength={9}
            disabled={!formProduto.flg_rastreavel.value}
            name="cod_agreg_rast"
            register={register}
            control={control}
            isError={!!errors.cod_agreg_rast}
            onInput={(ev: any) => {
              const value = parseFloat(ev.target.value.slice(0, 9));

              if (value <= 999999999) {
                setValue('cod_agreg_rast', value);
                changeFormProdutoLoja({
                  ...formProdutoLoja,
                  cod_agreg_rast: {
                    ...formProdutoLoja.cod_agreg_rast,
                    value,
                    isInvalid: !formProduto.flg_rastreavel.value,
                  },
                });
              }
            }}
          />
        </Col>
        <Col sm={12} md={2}>
          <p
            className="sc-iqHYGH bMXcXH me-2"
            style={{
              marginTop: '10px',
              marginLeft: '0px',
              position: 'absolute',
            }}
          >
            Data de Fabricação
          </p>
          <InpuTypeDate
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setValue('dta_fabricacao_rast', e.target.value);
              changeFormProdutoLoja({
                ...formProdutoLoja,
                dta_fabricacao_rast: {
                  ...formProdutoLoja.dta_fabricacao_rast,
                  value: e.target.value,
                },
              });
            }}
            value={formProdutoLoja.dta_fabricacao_rast.value}
            type="date"
          />
        </Col>
      </Row>
    </InterfaceContainer>
  );
};
